import api from "./index";

export default {
  async index(page) {
    try {
      return await api.get(`/account/api/favourites?page=${page}`);
    } catch (error) {
      return false;
    }
  },
  async toggle({ id, type }) {
    try {
      return await api.post("/account/api/favourites/toggle_favourite", {
        listable_id: id,
        listable_type: type
      });
    } catch (error) {
      return false;
    }
  },
  async batchAdd(ids, type) {
    try {
      return await api.post("/account/api/favourites/batch_add_favourites", {
        listable_ids: ids,
        listable_type: type
      });
    } catch (error) {
      return false;
    }
  }
};
